<template>
<b-container fluid>
  <p></p><p></p>
  <b-row>
    <b-col></b-col>
    <b-col cols="7">
  <div class="article">
    <div>
      <div>
        <h1>History of Sudoku</h1>
      </div>
    </div>
    
    <div>
      <div>
        <p>  <img :src="getImgUrl('sdkhis.jpeg')" />
     </p>
        <p>The popular Japanese puzzle game Sudoku is based on the logical placement of numbers. An online game of logic, Sudoku doesn’t require any calculation nor special math skills; all that is needed are brains and concentration.</p>
        <p>
         The long and interesting history of the Sudoku is quite a puzzle in itself.

        <p>
         The name Sudoku or more correctly 数独 comes from Japan and consists of the Japanese characters Su (meaning 'number') and Doku (meaning ‘single’) but it was not invented in Japan. Sudoku originated in Switzerland and then traveled to Japan by way of America.

        </p>
        <p>
      
Sudoku has its deep roots in ancient number puzzles. For many centuries people have been interested in creating and solving them. Puzzles continue to stimulate new development in mathematics, as you can see in the film A Beautiful Mind ➚.  </p>
 
        <p>
          The history of Sudoku mirrors the game itself. It is simple yet not as straightforward and clear as one might think. The frequent misconception that it is an Asian puzzle serves to prove its unusual history and many people are surprised to find it is actually a Western creation. It took almost 100 years for the game to develop into its final form and reach its current worldwide popularity, but as the saying goes “slow and steady wins the race” and Sudoku is now clearly here to stay.
        </p>
        <h2>The swiss beginnings in the history of Sudoku</h2>
        <p>
        The history of Sudoku began to be written in the 18th century, in Switzerland, thanks to the physicist and mathematician Leonhard Euler (1707-1783).
   </p>
        <p>
      Euler combined the concepts of Latin Square and Magic Squares to devise a mathematical system to be used in statistical analysis. The name Latin Square stands for the use of the Latin alphabet to fill a matrix or square while Magic Square refers to the placement of numbers within a matrix, according to a mathematical formula, in such a way that the sum of each column and row is always the same.
   </p>
        <p>
        In his paper “De quadratis magicis” of 1776, Euler applied several constraints to a table of 9, 16, 25 and 36 cells showing how to create a Magic Square using the Latin Alphabet.
Euler’s concept was yet far from Sudoku, but the principles behind its paper would later serve as an inspiration for the creation of the number puzzle so popular nowadays.
  </p>
  <h2>The Birth of Sudoku
</h2>
        <p>
       The first record of the modern Sudoku can be traced to New York and the Dell Pencil Puzzles and Word Games, a set of magazines known for decades for its crosswords and written puzzles. In 1979, Dell published a puzzle number game called “Number Place”, where players had to place the numbers from 1 to 9 in a 9 x 9 grid, without repeating any digit per column or row. These rules are now very familiar to any Sudoku player, but they came as an innovation back then.
  </p>
        <p>
     Howard Garns, a retired architect and freelance puzzle inventor from Indianapolis, is believed to be the mind behind those puzzles, although he was never credited for them. Nevertheless, several anecdotes seem to corroborate him as the creator of the modern Sudoku.
    </p>
    <p>“Number Place” quickly became a favorite among Dell’s puzzles but the history of Sudoku was only beginning and its expansion throughout the world would only come when Japan took notice of it.
</p>
      </div>
    </div>
    <div>
      <div>
        <h2>The Japanese takeover
</h2>
      </div>
    </div>
    <div>
      <div>
        <p>
       In 1984, Sudoku reached the Land of the Rising Sun through the Monthly Nikolist Magazine, by Nikoli publishers.    </p>
      <p>The game published under the name “Suuji wa dokushin ni kaguru” (The numbers must remain single), followed the same principles of Dell’s “Number Place” but Nikoli introduced two new rules to it.
      </p>
      <p>The first determined that the allocated numbers at the beginning of each puzzle should create different patterns. The goal was to make this number puzzle visually more appealing to the public.
      The second rule was that no more than 32 out of the 81 total numbers of a traditional grid could be allocated at the beginning of the puzzle. This threshold guarantees that the game is sufficiently challenging even at an easy difficulty level.
      </p>
      <p>The game was a major success in Japan and quickly became widespread. Due to its popularity, its name was eventually shortened to include only the characters for number (数 - Su) and single (独 - Doku), thus becoming Sudoku (pronounced similarly to Sue-Dough-Koo).
      </p>
      <p>The history of Sudoku took its final shape in Japan, but its world conquest was still to come.
        </p>
        <h2>The final expansion
        </h2>
        <p>In 1997, the New Zealander Wayne Gould, a retired judge from the criminal courts of Hong Kong and a puzzle enthusiast, discovered Sudoku while wandering in a bookstore in Tokyo. The simplicity of these challenging puzzles immediately took him over and he became an instant fan.
        </p>
        <p>He dedicated his following years to write a computer program that could generate Sudoku puzzles and rate their difficulty.
        </p>
        <p>In 2004 the history of Sudoku finally completed its full circle and returned to the West, when Gould successfully convinced The London Times to publish some of his puzzles. The phenomenon was launched. Newspapers, magazines, and book publishers, first from the United Kingdom and the United States and later from the world, followed suit and Sudoku finally achieved its status as one of the most popular and widespread puzzle games.</p>
            <p>Despite not being its creator, the role of Gould and the subsequent worldwide success of Sudoku granted him a placement among the 100 most influential people of 2006 by Time magazine.
</p>  
             <p>2006 
also
 marked the beginning of the World Sudoku Championship with individuals and national teams from all over the globe competing to solve the hardest levels of these number puzzles in the fastest time possible.</p> 
              </div>
              </div>
</div>
</b-col>
<b-col cols="3"></b-col>
</b-row>
</b-container>
</template>
<script>
export default {
 setup(){
      return{
         }
  },
  methods: {
      getImgUrl(pic) {
      return require("../assets/" + pic);
       }
  }
}
</script>

<style>
.article {
  padding: 20px;
 /* border: 1px solid #ccc;*/
  border-radius: 5px;
  margin-bottom: 20px;
  justify-content: left;
  text-align: left;
}

</style>